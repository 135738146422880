.search{
    background: var(--five-color);
    height: 50px;
    display: flex;
    align-items: center;
    margin: 15px;
    border-radius: 5px;
}

.search .icon{
    margin-left: 8px;
    color: gray;

}

.search input{
    height: 100%;
    width: 100%;
    background: none;
    outline: none;
    border: none;
    padding-left: 8px;
    font-family: Montserrat;
    font-weight: bold;
    color: #fff;
}


.search input:focus::placeholder{
    color: #fff;
}

