.widgets {
  flex: 0.3;
  background: rgb(14, 13, 13);
  backdrop-filter: blur(10px);
  height: 1000px;
  z-index: 1100;
}

.popular__box::-webkit-scrollbar {
  width: 2px;
  scroll-behavior: smooth;
}

::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background: #E50914;
}

.tag__box {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
}

.widgets .heading {
  margin: 13px 0;
  padding-left: 0;
  font-size: 18px;
  color: #E50914;
}

.popular__box {
  padding: 0px 15px;
  overflow-y: scroll;
  height: 100vh;
}


@media (max-width:1000px) {
  .widgets{
    display: none;
  }
}
