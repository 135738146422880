.movieDashboard{
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.layer{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100vh;
    overflow: hidden;
    background: linear-gradient(211deg,transparent, rgba(0, 0, 0, 0.701), #000);
}

.return {
    border: red solid 2px;
    background-color: #000;
    padding: 7px 7px;
}

.movie__content{
    position: absolute;
    width: 100%;
    bottom: 3rem;
    color: #fff;
    left: 5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.vote{
    background: var(--second-color);
    width:fit-content;
    padding: 5px 10px;
    height: 60px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
    border: red solid 2px;
}

.vote span{
    font-size: 18px;
}

.overview {
    background-color: rgb(34, 33, 33);
    opacity: .7;
    border: red solid 2px;
    padding: 10px 10px;
}

.name{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.name h3{
    font-size: 30px;
    background-color: rgb(34, 33, 33);
    opacity: .7;
    border: red solid 2px;
    padding: 10px 10px;
    width: fit-content;
}

.name p{
    line-height: 25px;
    font-size: 16px;
    max-width: 500px;
}

.name p span{
    color: var(--second-color);
    cursor: pointer;
    }

.movie__content .button__box button{
    background: var(--second-color);
    color: var(--four-color);
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    border: red solid 2px;
}

.back__icon{
    position: absolute;
    top: 2rem;
    left: 5rem;
    color: var(--four-color);
    cursor: pointer;
}

.back__icon .MuiSvgIcon-root{
    font-size: 30px;
    color: var(--four-color);
}


@media (max-width:850px) {
    .movie__content{
        left: 0;
        padding: 2rem;
    }

    .movie__content h3{
        font-size: 25px;
    }

    .back__icon{
        left: 3rem;
    }

    .movie__content p{
        max-width: 400px;
        margin-right:  15px;
    }
}


@media (max-width:450px) {
    .movie__content{
        left: 0;
        padding: 0 1rem;
    }

    .back__icon{
        left: 1rem;
    }

    .movie__content h3{
        font-size: 20px;
    }

    .movie__content p{
        font-size: 15px;
    }
}

