.moviesRow{
    border-radius: 3px;
    transition: .3s;
    position: relative;
    margin-bottom: 10px;
    border: solid 1px #83151b;
}

.moviesRow img:hover{
    transform: scale(1.1);
    border: 1px solid #222;
}

.fav{
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.50);
    color: var(--second-color);
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
    backdrop-filter: blur(10px);
}

.fav .MuiSvgIcon-root:hover{
    color: var(--second-color);
}


.moviesRow img{
    max-height: 250px;
    border-radius: 3px;
    object-fit: cover;
    margin-bottom: 10px;
}


.gridMovies{
    flex: 1 1;
}


@media (max-width:350px) {
    .gridMovies{
        flex: 1;
    }

    .gridMovies img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}