@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);

:root {
  --main-color: #01a3ff;
  --second-color: #1fdf64;
  --third-color: #222222;
  --four-color: #ffffff;
  --five-color:#2e2e31;
}

body {
  margin: 0;
  font-family: Montserrat;
  font-weight: bold;
  box-sizing: border-box;
  transition: .3s;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: .3s;
}


html{
  overflow: hidden;
}

