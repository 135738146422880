.popularMovies{
    margin-top: 1rem;
    width: 100%;
    height: 200px;
    background: var(--five-color);
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.popularMovies::after{
    content: '';
    background: rgba(0, 0, 0, 0.50);
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    backdrop-filter: blur(5px);
}


.popularMovies:hover::after{
    width: 100%;
}

.popularMovies .banner__img{
    width: 100%;
    height: 100%;
}

.popularMovies .banner__img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    border: solid 2px #E50914;
}

.popular__content{
    z-index: 1100;
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    display: none;
}

.popular__content span{
    font-size: 28px;
}

.popular__content p{
    font-size: 14px;
    color: rgb(157, 154, 154);
    margin-top: 5px;
}

.popularMovies:hover .popular__content{
    display: block;
}