.discovers {
    width: 100%;
    height: 50em;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
    z-index: 1;
    
}

.discovers::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.30);
    border-radius: 5px;
}


.discover__img {
    width: 100%;
    height: 100%;
}

.discover__img img {
    width: 100%;
    height: 120%;
    border-radius: 3px;
    object-fit: cover;
}


.discover__content{
    width: 100%;
    z-index: 1100;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 80px 80px;
    position: absolute;
    background: linear-gradient(180deg , transparent , rgba(0,0,0,0.60) , rgba(0,0,0,0.80));
    bottom: 0;
    left: 0;
    border-radius: 3px;
}

.discover__left p {
    max-width: 600px;
    line-height: 23px;
}

.discover__left h3 span {
    color: var(--second-color);
}

.right__discover {
    display: flex;
    align-items: center;
    gap: 5px;
    color: gold;
}


.discover__left p {
    font-weight: 300;
    margin-top: 5px;
}

.button__box {
    display: flex;
    gap: 10px;
}

.button__box button {
    padding: 9px 28px;
    font-family: Montserrat;
    font-weight: bold;
    border-radius: 3px;
    margin: 15px 0;
    border: none;
    background: rgb(59, 59, 59);
    color: #fff;
    box-shadow: #000 0 2px 10px;
    cursor: pointer;
}

.button__box button:hover {
    background: #000;
    box-shadow: #222 0 2px 10px;
}


@media (max-width:650px) {
    .discover__left p {
        font-size: 13px;
    }
}

@media (max-width:400px) {
    .discovers {
        height: 360px;
    }

    .discover__content {
        align-items: flex-end;
    }

    .button__box button:nth-child(2) {
        display: none;
    }
}